export enum PowerCard {
  Bear1 = 10,
  Bear2 = 11,
  Fox1 = 20,
  Fox2 = 21,
  Moose1 = 30,
  Moose2 = 31,
  Orca1 = 40,
  Orca2 = 41,
  Puffin1 = 50,
  Puffin2 = 51,
  Walrus1 = 60,
  Walrus2 = 61,
}