import { AnimalCard } from './AnimalCard'

export const animalPile1 = [
  AnimalCard.FoxMoose1,
  AnimalCard.FoxWalrus2,
  AnimalCard.FoxOrca3,
  AnimalCard.FoxPuffin4,
  AnimalCard.FoxBear5,
  AnimalCard.MooseWalrus1,
  AnimalCard.MooseOrca2,
  AnimalCard.MoosePuffin3,
  AnimalCard.MooseBear4,
  AnimalCard.MooseFox5,
  AnimalCard.WalrusOrca1,
  AnimalCard.WalrusPuffin2,
  AnimalCard.WalrusBear3,
  AnimalCard.WalrusFox4,
  AnimalCard.WalrusMoose5,
  AnimalCard.OrcaPuffin1,
  AnimalCard.OrcaBear2,
  AnimalCard.OrcaFox3,
  AnimalCard.OrcaMoose4,
  AnimalCard.OrcaWalrus5,
  AnimalCard.PuffinBear1,
  AnimalCard.PuffinFox2,
  AnimalCard.PuffinMoose3,
  AnimalCard.PuffinWalrus4,
  AnimalCard.PuffinOrca5,
  AnimalCard.BearFox1,
  AnimalCard.BearMoose2,
  AnimalCard.BearWalrus3,
  AnimalCard.BearOrca4,
  AnimalCard.BearPuffin5,
]


export const animalPile2 = [
  AnimalCard.FoxWalrus1,
  AnimalCard.FoxOrca2,
  AnimalCard.FoxPuffin3,
  AnimalCard.FoxBear4,
  AnimalCard.FoxMoose5,
  AnimalCard.MooseOrca1,
  AnimalCard.MoosePuffin2,
  AnimalCard.MooseBear3,
  AnimalCard.MooseFox4,
  AnimalCard.MooseWalrus5,
  AnimalCard.WalrusPuffin1,
  AnimalCard.WalrusBear2,
  AnimalCard.WalrusFox3,
  AnimalCard.WalrusMoose4,
  AnimalCard.WalrusOrca5,
  AnimalCard.OrcaBear1,
  AnimalCard.OrcaFox2,
  AnimalCard.OrcaMoose3,
  AnimalCard.OrcaWalrus4,
  AnimalCard.OrcaPuffin5,
  AnimalCard.PuffinFox1,
  AnimalCard.PuffinMoose2,
  AnimalCard.PuffinWalrus3,
  AnimalCard.PuffinOrca4,
  AnimalCard.PuffinBear5,
  AnimalCard.BearMoose1,
  AnimalCard.BearWalrus2,
  AnimalCard.BearOrca3,
  AnimalCard.BearPuffin4,
  AnimalCard.BearFox5,
]


export const animalPile3 = [
  AnimalCard.FoxOrca1,
  AnimalCard.FoxPuffin2,
  AnimalCard.FoxBear3,
  AnimalCard.FoxMoose4,
  AnimalCard.FoxWalrus5,
  AnimalCard.MoosePuffin1,
  AnimalCard.MooseBear2,
  AnimalCard.MooseFox3,
  AnimalCard.MooseWalrus4,
  AnimalCard.MooseOrca5,
  AnimalCard.WalrusBear1,
  AnimalCard.WalrusFox2,
  AnimalCard.WalrusMoose3,
  AnimalCard.WalrusOrca4,
  AnimalCard.WalrusPuffin5,
  AnimalCard.OrcaFox1,
  AnimalCard.OrcaMoose2,
  AnimalCard.OrcaWalrus3,
  AnimalCard.OrcaPuffin4,
  AnimalCard.OrcaBear5,
  AnimalCard.PuffinMoose1,
  AnimalCard.PuffinWalrus2,
  AnimalCard.PuffinOrca3,
  AnimalCard.PuffinBear4,
  AnimalCard.PuffinFox5,
  AnimalCard.BearWalrus1,
  AnimalCard.BearOrca2,
  AnimalCard.BearPuffin3,
  AnimalCard.BearFox4,
  AnimalCard.BearMoose5,
]


export const animalPile4 = [
  AnimalCard.FoxPuffin1,
  AnimalCard.FoxBear2,
  AnimalCard.FoxMoose3,
  AnimalCard.FoxWalrus4,
  AnimalCard.FoxOrca5,
  AnimalCard.MooseBear1,
  AnimalCard.MooseFox2,
  AnimalCard.MooseWalrus3,
  AnimalCard.MooseOrca4,
  AnimalCard.MoosePuffin5,
  AnimalCard.WalrusFox1,
  AnimalCard.WalrusMoose2,
  AnimalCard.WalrusOrca3,
  AnimalCard.WalrusPuffin4,
  AnimalCard.WalrusBear5,
  AnimalCard.OrcaMoose1,
  AnimalCard.OrcaWalrus2,
  AnimalCard.OrcaPuffin3,
  AnimalCard.OrcaBear4,
  AnimalCard.OrcaFox5,
  AnimalCard.PuffinWalrus1,
  AnimalCard.PuffinOrca2,
  AnimalCard.PuffinBear3,
  AnimalCard.PuffinFox4,
  AnimalCard.PuffinMoose5,
  AnimalCard.BearOrca1,
  AnimalCard.BearPuffin2,
  AnimalCard.BearFox3,
  AnimalCard.BearMoose4,
  AnimalCard.BearWalrus5,
]